import { createContext, Dispatch, ReactNode, useReducer } from 'react';
import { QuizReducer } from './Quiz.reducer';
import { QuizAction, QuizState } from './Quiz.types';

const initialState: QuizState = {
  step: 1,
  nextButtonIsDisabled: false,
  total: 'От 300 до 500 тыс.рублей',
  estate: 'Да',
  kind: [],
  connection: 'Телефон',
  hasKindError: false,
  name: '',
  phone: '',
  policyIsChecked: true,
  policyIsOpen: false,
  thanksIsOpen: false,
};

export const QuizContext = createContext<{
  state: QuizState;
  dispatch: Dispatch<QuizAction>;
}>({ state: initialState, dispatch: () => initialState });

export const QuizProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(QuizReducer, initialState);

  return (
    <QuizContext.Provider value={{ state, dispatch }}>
      {children}
    </QuizContext.Provider>
  );
};
