import ReactDOM from 'react-dom';
import { Textual } from 'components/elements';
import { Modal } from 'components/layout';
import { Portal, Transition } from 'components/other';
import { useState } from 'react';
import { TextualModalProps } from './TextualModal.props';

const rootElem = document.getElementById('root');

export const TextualModal = ({ type }: TextualModalProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => setIsOpen(false);

  return (
    <Transition
      isActive={isOpen}
      timeout={300}
      onExitDone={() => rootElem && ReactDOM.unmountComponentAtNode(rootElem)}
    >
      {(transitionStyles) => (
        <Portal withBlocking>
          <Modal className={transitionStyles} onOverlayClick={handleClose}>
            <Textual type={type} onCloseClick={handleClose} />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
