import { useContext } from 'react';
import { QuizContext } from '..';
import styles from './QuizControl.module.scss';

export const QuizControl = (): JSX.Element => {
  const { state, dispatch } = useContext(QuizContext);
  const { step, nextButtonIsDisabled, kind } = state;

  const handleNext = () => {
    if (step !== 3) {
      dispatch({ type: 'nextStep' });
    } else {
      if (kind.length === 0) {
        dispatch({ type: 'setKindError', hasKindError: true });
      } else {
        dispatch({ type: 'nextStep' });
        dispatch({ type: 'setKindError', hasKindError: false });
      }
    }
  };

  return (
    <div className={styles.module}>
      {step > 1 && step < 5 && (
        <button
          className={styles.prev}
          type="button"
          onClick={() => dispatch({ type: 'prevStep' })}
        >
          Назад
        </button>
      )}
      {step < 5 && (
        <button
          className={styles.next}
          type="button"
          onClick={handleNext}
          disabled={nextButtonIsDisabled}
        >
          {step === 1 ? 'Начать расчет' : 'Далее'}
        </button>
      )}
      {step === 5 && <button className={styles.send}>Получить расчет</button>}
    </div>
  );
};
