import { Checkbox } from 'components/forms';
import { ChangeEventHandler, useContext } from 'react';
import { QuizContext } from '..';
import { KindType } from '../Quiz.types';
import styles from './QuizThree.module.scss';

export const QuizThree = (): JSX.Element => {
  const { state, dispatch } = useContext(QuizContext);
  const { kind, hasKindError } = state;

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch({ type: 'setKind', kind: event.target.value as KindType });
  };

  return (
    <div className={styles.module}>
      <h3 className={styles.title}>Какие у вас долги?</h3>
      <div className={styles.list}>
        {hasKindError && (
          <p className={styles.error}>Выберите один из вариантов</p>
        )}

        <label className={styles.item}>
          <Checkbox
            className={styles.checkbox}
            inverted
            name="kind"
            value="Кредиты"
            checked={kind.some((item) => item === 'Кредиты')}
            onChange={handleChange}
          />
          <span className={styles.name}>Кредиты</span>
        </label>
        <label className={styles.item}>
          <Checkbox
            className={styles.checkbox}
            inverted
            name="kind"
            value="Займы"
            checked={kind.some((item) => item === 'Займы')}
            onChange={handleChange}
          />
          <span className={styles.name}>Займы</span>
        </label>
        <label className={styles.item}>
          <Checkbox
            className={styles.checkbox}
            inverted
            name="kind"
            value="Налоги"
            checked={kind.some((item) => item === 'Налоги')}
            onChange={handleChange}
          />
          <span className={styles.name}>Налоги</span>
        </label>
        <label className={styles.item}>
          <Checkbox
            className={styles.checkbox}
            inverted
            name="kind"
            value="ЖКХ"
            checked={kind.some((item) => item === 'ЖКХ')}
            onChange={handleChange}
          />
          <span className={styles.name}>ЖКХ</span>
        </label>
        <label className={styles.item}>
          <Checkbox
            className={styles.checkbox}
            inverted
            name="kind"
            value="Иные долги"
            checked={kind.some((item) => item === 'Иные долги')}
            onChange={handleChange}
          />
          <span className={styles.name}>Иные долги</span>
        </label>
      </div>
    </div>
  );
};
