import { useContext } from 'react';
import { CallbackContext } from '..';
import { CallbackModalProps } from './CallbackModalProps';
import { Modal } from 'components/layout';
import { Portal, Transition } from 'components/other';
import { CallbackModalBody } from '../CallbackModalBody/CallbackModalBody';

export const CallbackModal = ({ type }: CallbackModalProps): JSX.Element => {
  const { state, dispatch } = useContext(CallbackContext);

  return (
    <Transition
      isActive={state.callbackIsOpen}
      timeout={300}
      onExitDone={() =>
        !state.policyIsOpen &&
        !state.thanksIsOpen &&
        dispatch({ type: 'unmount' })
      }
    >
      {(transitionStyles) => (
        <Portal withBlocking>
          <Modal
            onOverlayClick={() => dispatch({ type: 'closeCallback' })}
            className={transitionStyles}
          >
            <CallbackModalBody type={type} />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
