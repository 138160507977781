import cn from 'classnames';
import { TextFieldProps } from './TextField.props';
import styles from './TextField.module.scss';

export const TextField = ({
  className,
  ...rest
}: TextFieldProps): JSX.Element => {
  const moduleStyles = cn(styles.module, className);

  return <input className={moduleStyles} {...rest} />;
};
