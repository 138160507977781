import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Callback, CallbackProvider } from 'views/Callback';

const initButton = () => {
  const button = document.querySelector<HTMLButtonElement>('.screen__button');

  button?.addEventListener('click', () => {
    ReactDOM.render(
      <StrictMode>
        <CallbackProvider>
          <Callback type="consultation" />
        </CallbackProvider>
      </StrictMode>,
      document.getElementById('root')
    );
  });
};

const screen = {
  init: () => {
    initButton();
  },
};

export default screen;
