const initPanel = () => {
  const list = document.querySelector('.panel__list');
  const span = list?.querySelector('span');
  const button = list?.querySelector('button');

  span?.addEventListener('click', () => {
    list?.classList.add('panel__list--active');
  });

  button?.addEventListener('click', () => {
    list?.classList.remove('panel__list--active');
  });
};

const answers = {
  init: () => {
    initPanel();
  },
};

export default answers;
