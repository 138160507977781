import { Radio } from 'components/forms/Radio/Radio';
import { ChangeEventHandler, useContext } from 'react';
import { QuizContext } from '..';
import { ConnectionType } from '../Quiz.types';
import styles from './QuizFour.module.scss';

export const QuizFour = (): JSX.Element => {
  const { state, dispatch } = useContext(QuizContext);
  const { connection } = state;

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch({
      type: 'setConnection',
      connection: event.target.value as ConnectionType,
    });
  };

  return (
    <div className={styles.module}>
      <h3 className={styles.title}>Куда направить расчет?</h3>
      <div className={styles.list}>
        <label className={styles.item}>
          <Radio
            className={styles.radio}
            name="connection"
            value="Телефон"
            checked={connection === 'Телефон'}
            onChange={handleChange}
          />
          <span className={styles.name}>Сообщить по телефону</span>
        </label>
        <label className={styles.item}>
          <Radio
            className={styles.radio}
            name="connection"
            value="WhatsApp"
            checked={connection === 'WhatsApp'}
            onChange={handleChange}
          />
          <img
            className={styles.icon}
            src="/build/images/whatsapp-icon.png"
            alt="whatsapp-icon"
          />
          <span className={styles.name}>Направить в WhatsApp</span>
        </label>
        <label className={styles.item}>
          <Radio
            className={styles.radio}
            name="connection"
            value="Telegram"
            checked={connection === 'Telegram'}
            onChange={handleChange}
          />
          <img
            className={styles.icon}
            src="/build/images/telegram-icon.png"
            alt="telegram-icon"
          />
          <span className={styles.name}>Направить в Telegram</span>
        </label>
      </div>
    </div>
  );
};
