import { QuizAction, QuizState } from './Quiz.types';

export const QuizReducer = (
  state: QuizState,
  action: QuizAction
): QuizState => {
  switch (action.type) {
    case 'prevStep':
      return { ...state, step: state.step - 1, nextButtonIsDisabled: false };
    case 'nextStep':
      return { ...state, step: state.step + 1 };
    case 'setNextButtonIsDisabled':
      return { ...state, nextButtonIsDisabled: action.isDisabled };
    case 'setTotal':
      return { ...state, total: action.total };
    case 'setEstate':
      return { ...state, estate: action.estate };
    case 'setKind':
      if (state.kind.some((item) => item === action.kind)) {
        return {
          ...state,
          kind: state.kind.filter((item) => item !== action.kind),
        };
      } else {
        return {
          ...state,
          kind: [...state.kind, action.kind],
          hasKindError: false,
        };
      }
    case 'setKindError':
      return { ...state, hasKindError: action.hasKindError };

    case 'setConnection':
      return { ...state, connection: action.connection };
    case 'setName':
      return { ...state, name: action.name };
    case 'setPhone':
      return { ...state, phone: action.phone };
    case 'togglePolicy':
      return { ...state, policyIsChecked: !state.policyIsChecked };
    case 'openPolicy':
      return { ...state, policyIsOpen: true };
    case 'closePolicy':
      return { ...state, policyIsOpen: false };
    case 'openThanks':
      return { ...state, thanksIsOpen: true };
    case 'closeThanks':
      return { ...state, thanksIsOpen: false };
    case 'clear':
      return {
        ...state,
        step: 1,
        total: '',
        estate: 'Да',
        kind: [],
        connection: 'Телефон',
        name: '',
        phone: '',
        thanksIsOpen: true,
      };

    default:
      return state;
  }
};
