import { Thanks } from 'components/elements';
import { Modal } from 'components/layout';
import { Portal, Transition } from 'components/other';
import { useContext } from 'react';
import { CallbackContext } from '..';

export const CallbackThanks = (): JSX.Element => {
  const { state, dispatch } = useContext(CallbackContext);

  const handleClose = () => dispatch({ type: 'closeThanks' });

  return (
    <Transition
      isActive={state.thanksIsOpen}
      timeout={300}
      onExitDone={() =>
        !state.callbackIsOpen &&
        !state.policyIsOpen &&
        dispatch({ type: 'unmount' })
      }
    >
      {(transitionStyles) => (
        <Portal withBlocking>
          <Modal className={transitionStyles} onOverlayClick={handleClose}>
            <Thanks onCloseClick={handleClose} />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
