import { createContext, Dispatch, ReactNode, useReducer } from 'react';
import { callbackReducer } from './Callback.reducer';
import { CallbackState, CallbackAction } from './Callback.types';

const initialState: CallbackState = {
  name: '',
  phone: '',
  policyIsChecked: true,
  callbackIsOpen: true,
  thanksIsOpen: false,
  policyIsOpen: false,
  isMounted: true,
};

export const CallbackContext = createContext<{
  state: CallbackState;
  dispatch: Dispatch<CallbackAction>;
}>({ state: initialState, dispatch: () => initialState });

export const CallbackProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [state, dispatch] = useReducer(callbackReducer, initialState);

  return (
    <CallbackContext.Provider value={{ state, dispatch }}>
      {children}
    </CallbackContext.Provider>
  );
};
