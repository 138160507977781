import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import Swiper, { Navigation, Pagination } from 'swiper';
import { Callback, CallbackProvider } from 'views/Callback';

Swiper.use([Navigation, Pagination]);

const initSlider = () => {
  const more =
    document.querySelector<HTMLParagraphElement>('.team .more-mobile');

  new Swiper('.team__slider .swiper-container', {
    spaceBetween: 20,

    navigation: {
      nextEl: '.team__next',
      prevEl: '.team__prev',
    },

    // pagination: {
    //   el: '.complexes__pagination',
    //   clickable: true,
    // },

    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },

    on: {
      slideChange(swiper) {
        if (!more) {
          return;
        }
        if (swiper.activeIndex < swiper.slides.length - 1) {
          more.classList.remove('hide');
        } else {
          more.classList.add('hide');
        }
      },
    },
  });
};

const initButtons = () => {
  const buttons = document.querySelectorAll<HTMLButtonElement>('.team__button');

  buttons.forEach((button) =>
    button?.addEventListener('click', () => {
      ReactDOM.render(
        <StrictMode>
          <CallbackProvider>
            <Callback type="help" />
          </CallbackProvider>
        </StrictMode>,
        document.getElementById('root')
      );
    })
  );
};

const team = {
  init: () => {
    initSlider();
    initButtons();
  },
};

export default team;
