import './styles/index.scss';

import header from './scripts/header';
import panel from './scripts/panel';
import screen from './scripts/screen';
import debts from './scripts/debts';
import certificates from './scripts/certificates';
import cases from './scripts/cases';
import reviews from './scripts/reviews';
import guarantee from './scripts/guarantee';
import calculation from './scripts/calculation';
import team from './scripts/team';
import answers from './scripts/answers';
import footer from './scripts/footer';

header.init();
panel.init();
screen.init();
debts.init();
certificates.init();
cases.init();
reviews.init();
guarantee.init();
calculation.init();
team.init();
answers.init();
footer.init();
