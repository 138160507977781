import { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CallbackContext } from '.';
import { CallbackProps } from './Callback.props';
import { CallbackModal } from './CallbackModal/CallbackModal';
import { CallbackPolicy } from './CallbackPolicy/CallbackPolicy';
import { CallbackThanks } from './CallbackThanks/CallbackThanks';

const rootElem = document.getElementById('root');

export const Callback = ({ type }: CallbackProps): JSX.Element => {
  const { state } = useContext(CallbackContext);

  useEffect(() => {
    if (!state.isMounted && rootElem) {
      ReactDOM.unmountComponentAtNode(rootElem);
    }
  }, [state.isMounted]);

  return (
    <>
      <CallbackModal type={type} />
      <CallbackThanks />
      <CallbackPolicy />
    </>
  );
};
