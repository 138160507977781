import { Radio } from 'components/forms/Radio/Radio';
import { ChangeEventHandler, useContext } from 'react';
import { QuizContext } from '..';
import { TotalType } from '../Quiz.types';
import styles from './QuizOne.module.scss';

export const QuizOne = (): JSX.Element => {
  const { state, dispatch } = useContext(QuizContext);
  const { total } = state;

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value as TotalType;
    dispatch({ type: 'setTotal', total: value });
    dispatch({ type: 'setNextButtonIsDisabled', isDisabled: !value });
  };

  return (
    <div className={styles.module}>
      <h3 className={styles.title}>Общая сумма долга</h3>
      <p className={styles.subtitle}>(кредиты + налоги + ЖКХ)</p>
      <div className={styles.list}>
        <label className={styles.item}>
          <Radio
            className={styles.radio}
            name="total"
            value=""
            checked={total === ''}
            onChange={handleChange}
          />
          <span className={styles.name}>
            До 300 000 руб.{' '}
            <small>(в этом случае мы не можем Вам помочь)</small>
          </span>
        </label>
        <label className={styles.item}>
          <Radio
            className={styles.radio}
            name="total"
            value="От 300 до 500 тыс.рублей"
            checked={total === 'От 300 до 500 тыс.рублей'}
            onChange={handleChange}
          />
          <span className={styles.name}>От 300 до 500 тыс.рублей</span>
        </label>
        <label className={styles.item}>
          <Radio
            className={styles.radio}
            name="total"
            value="От 500 000 до 1 000 000"
            checked={total === 'От 500 000 до 1 000 000'}
            onChange={handleChange}
          />
          <span className={styles.name}>От 500 000 до 1 000 000</span>
        </label>
        <label className={styles.item}>
          <Radio
            className={styles.radio}
            name="total"
            value="Более 1 000 000"
            checked={total === 'Более 1 000 000'}
            onChange={handleChange}
          />
          <span className={styles.name}>Более 1 000 000</span>
        </label>
      </div>
    </div>
  );
};
