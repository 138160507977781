import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import Swiper, { Navigation, Pagination } from 'swiper';
import GLightbox from 'glightbox';
import { Callback, CallbackProvider } from 'views/Callback';

Swiper.use([Navigation, Pagination]);

const initSlider = () => {
  const more = document.querySelector<HTMLParagraphElement>(
    '.cases .more-mobile'
  );

  new Swiper('.cases__slider .swiper-container', {
    spaceBetween: 20,

    navigation: {
      nextEl: '.cases__next',
      prevEl: '.cases__prev',
    },

    // pagination: {
    //   el: '.complexes__pagination',
    //   clickable: true,
    // },

    breakpoints: {
      // 768: {
      //   slidesPerView: 2,
      //   spaceBetween: 30,
      // },
      1200: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
    },

    on: {
      slideChange(swiper) {
        if (!more) {
          return;
        }
        if (swiper.activeIndex < swiper.slides.length - 1) {
          more.classList.remove('hide');
        } else {
          more.classList.add('hide');
        }
      },
    },
  });
};

const initZoom = () => {
  const elements = document.querySelectorAll<HTMLDivElement>('.cases__image');
  elements.forEach((element) => {
    const items = element?.dataset.items
      ?.split(',')
      .map((item) => ({ href: item }));

    const gallery = GLightbox({
      elements: items,
    });

    element?.addEventListener('click', () => gallery.open());
  });
};

const initButtons = () => {
  const buttons =
    document.querySelectorAll<HTMLButtonElement>('.cases__button');

  buttons.forEach((button) =>
    button?.addEventListener('click', () => {
      ReactDOM.render(
        <StrictMode>
          <CallbackProvider>
            <Callback type="consultation" />
          </CallbackProvider>
        </StrictMode>,
        document.getElementById('root')
      );
    })
  );
};

const cases = {
  init: () => {
    initSlider();
    initZoom();
    initButtons();
  },
};

export default cases;
