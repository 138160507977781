import { useEffect, useRef } from 'react';
import cn from 'classnames';
import { TextualProps } from './Textual.props';
import styles from './Textual.module.scss';
import { CloseButton } from '..';

const policyText = document.getElementById('policy-text');
const rulesText = document.getElementById('rules-text');
const cookieText = document.getElementById('cookie-text');

const titleText = {
  policy: 'Политика конфиденциальности',
  rules: 'Пользовательское соглашение',
  cookie: 'Политика использования cookie',
};

export const Textual = ({
  type,
  onCloseClick,
  className,
}: TextualProps): JSX.Element => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }
    if (type === 'policy' && policyText) {
      contentRef.current.innerHTML = policyText.innerHTML;
    }
    if (type === 'rules' && rulesText) {
      contentRef.current.innerHTML = rulesText.innerHTML;
    }
    if (type === 'cookie' && cookieText) {
      contentRef.current.innerHTML = cookieText.innerHTML;
    }
  }, [type]);

  const moduleStyles = cn(styles.module, className);

  return (
    <div className={moduleStyles}>
      <CloseButton className={styles.close} onClick={onCloseClick} />
      <h3 className={styles.title}>{titleText[type]}</h3>
      <div className={styles.content} ref={contentRef} />
    </div>
  );
};
