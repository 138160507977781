import cn from 'classnames';
import styles from './Slider.module.scss';
import { SliderProps } from './Slider.props';

export const Slider = ({
  offsetBy,
  children,
  className,
}: SliderProps): JSX.Element => {
  const moduleStyles = cn(styles.module, className);

  return (
    <div className={moduleStyles}>
      <div
        className={styles.wrapper}
        style={{ transform: `translateX(${offsetBy * -100}%)` }}
      >
        {children}
      </div>
    </div>
  );
};
