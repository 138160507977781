import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Callback, CallbackProvider } from 'views/Callback';

const initButton = () => {
  const button =
    document.querySelector<HTMLButtonElement>('.guarantee__button');

  button?.addEventListener('click', () => {
    ReactDOM.render(
      <StrictMode>
        <CallbackProvider>
          <Callback type="consultation" />
        </CallbackProvider>
      </StrictMode>,
      document.getElementById('root')
    );
  });
};

const guarantee = {
  init: () => {
    initButton();
  },
};

export default guarantee;
