import { Slider } from 'components/layout';
import { FormEventHandler, useContext } from 'react';
import { QuizContext } from '..';
import { QuizControl } from '../QuizControl/QuizControl';
import { QuizFive } from '../QuizFive/QuizFive';
import { QuizFour } from '../QuizFour/QuizFour';
import { QuizOne } from '../QuizOne/QuizOne';
import { QuizThree } from '../QuizThree/QuizThree';
import { QuizTwo } from '../QuizTwo/QuizTwo';
import styles from './QuizBody.module.scss';

declare const ym: any;
declare const fbq: any;

export const QuizBody = (): JSX.Element => {
  const { state, dispatch } = useContext(QuizContext);
  const { step, total, kind, estate, connection, name, phone } = state;

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    ym(52597468, 'reachGoal', 'kick1');
    fbq('track', 'Lead');

    const comment = `
      Общая сумма долга: ${total}
      Есть ли имущество в собственности: ${estate}
      Вид долгов: ${kind.join(',')}
      Как связаться: ${connection}
    `;

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: 'quiz',
        name,
        phone,
        comment,
      }),
    });

    dispatch({ type: 'clear' });
  };

  return (
    <div className={styles.module}>
      <p className={styles.steps}>Шаг {step} из 5</p>
      <form className={styles.block} onSubmit={handleSubmit}>
        <Slider offsetBy={step - 1}>
          <QuizOne />
          <QuizTwo />
          <QuizThree />
          <QuizFour />
          <QuizFive />
        </Slider>
        <QuizControl />
        <img
          className={styles.image}
          src="/build/images/calculation-image.jpg"
          alt="calculation"
        />
      </form>
    </div>
  );
};
