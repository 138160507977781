import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Quiz, QuizProvider } from 'views/Quiz';

const initQuiz = () => {
  ReactDOM.render(
    <StrictMode>
      <QuizProvider>
        <Quiz />
      </QuizProvider>
    </StrictMode>,
    document.getElementById('quiz')
  );
};

const calculation = {
  init: () => {
    initQuiz();
  },
};

export default calculation;
