import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Callback, CallbackProvider } from 'views/Callback';
import { Cookie } from 'views/Cookie';
import { TextualModal } from 'views/TextualModal';

const initCallback = () => {
  const button = document.querySelector('.footer__callback');

  button?.addEventListener('click', () => {
    ReactDOM.render(
      <StrictMode>
        <CallbackProvider>
          <Callback type="callback" />
        </CallbackProvider>
      </StrictMode>,
      document.getElementById('root')
    );
  });
};

const initPolicy = () => {
  const button = document.getElementById('footer-policy');

  button?.addEventListener('click', () => {
    ReactDOM.render(
      <StrictMode>
        <TextualModal type="policy" />
      </StrictMode>,
      document.getElementById('root')
    );
  });
};

const initRules = () => {
  const button = document.getElementById('footer-rules');

  button?.addEventListener('click', () => {
    ReactDOM.render(
      <StrictMode>
        <TextualModal type="rules" />
      </StrictMode>,
      document.getElementById('root')
    );
  });
};

const initCookie = () => {
  const button = document.getElementById('footer-cookie');

  button?.addEventListener('click', () => {
    ReactDOM.render(
      <StrictMode>
        <TextualModal type="cookie" />
      </StrictMode>,
      document.getElementById('root')
    );
  });
};

const initCookieConfirmation = () => {
  ReactDOM.render(
    <StrictMode>
      <Cookie />
    </StrictMode>,
    document.getElementById('cookie')
  );
};

const footer = {
  init: () => {
    initCallback();
    initPolicy();
    initRules();
    initCookie();
    initCookieConfirmation();
  },
};

export default footer;
