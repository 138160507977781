import Swiper, { Navigation, Pagination } from 'swiper';
import GLightbox from 'glightbox';

Swiper.use([Navigation, Pagination]);

const initSlider = () => {
  const more = document.querySelector<HTMLParagraphElement>(
    '.answers .more-mobile'
  );

  new Swiper('.answers__slider .swiper-container', {
    spaceBetween: 20,

    navigation: {
      nextEl: '.answers__next',
      prevEl: '.answers__prev',
    },

    // pagination: {
    //   el: '.complexes__pagination',
    //   clickable: true,
    // },

    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },

    on: {
      slideChange(swiper) {
        if (!more) {
          return;
        }
        if (swiper.activeIndex < swiper.slides.length - 1) {
          more.classList.remove('hide');
        } else {
          more.classList.add('hide');
        }
      },
    },
  });
};

const initVideo = () => {
  GLightbox({
    selector: '.answers__slide',
    // autoplayVideos: true,
    // loop: true,
  });
};

const answers = {
  init: () => {
    initSlider();
    initVideo();
  },
};

export default answers;
