import Swiper, { Navigation, Pagination } from 'swiper';
import GLightbox from 'glightbox';

Swiper.use([Navigation, Pagination]);

const initSlider = () => {
  const more = document.querySelector<HTMLParagraphElement>(
    '.reviews .more-mobile'
  );

  new Swiper('.reviews__slider .swiper-container', {
    spaceBetween: 20,

    navigation: {
      nextEl: '.reviews__next',
      prevEl: '.reviews__prev',
    },

    // pagination: {
    //   el: '.complexes__pagination',
    //   clickable: true,
    // },

    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },

    on: {
      slideChange(swiper) {
        if (!more) {
          return;
        }
        if (swiper.activeIndex < swiper.slides.length - 1) {
          more.classList.remove('hide');
        } else {
          more.classList.add('hide');
        }
      },
    },
  });
};

const initVideo = () => {
  const items = document.querySelectorAll<HTMLAnchorElement>('.reviews__slide');
  items.forEach((item) => {
    item.addEventListener('click', (event) => {
      event.preventDefault();

      const myGallery = GLightbox({
        elements: [
          {
            href: item.href,
            type: 'video',
            source: 'local', //vimeo, youtube or local
            // width: 900,
          },
        ],
        autoplayVideos: false,
      });

      myGallery.open();
    });
  });

  // GLightbox({
  //   selector: '.reviews__slide',
  //   autoplayVideos: false,
  //   loop: false,
  // });
};

const reviews = {
  init: () => {
    initSlider();
    initVideo();
  },
};

export default reviews;
