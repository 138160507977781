import { useContext } from 'react';
import { CallbackContext } from '..';
import { Textual } from 'components/elements';
import { Modal } from 'components/layout';
import { Portal, Transition } from 'components/other';

export const CallbackPolicy = (): JSX.Element => {
  const { state, dispatch } = useContext(CallbackContext);

  const handleClose = () => dispatch({ type: 'closePolicy' });

  return (
    <Transition
      isActive={state.policyIsOpen}
      timeout={300}
      onExitDone={() =>
        !state.callbackIsOpen &&
        !state.thanksIsOpen &&
        dispatch({ type: 'unmount' })
      }
    >
      {(transitionStyles) => (
        <Portal withBlocking>
          <Modal className={transitionStyles} onOverlayClick={handleClose}>
            <Textual type="policy" onCloseClick={handleClose} />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
