import cn from 'classnames';
import { CloseButtonProps } from './CloseButton.props';
import styles from './CloseButton.module.scss';

export const CloseButton = ({
  className,
  ...rest
}: CloseButtonProps): JSX.Element => {
  const moduleStyles = cn(styles.module, className);

  return <button className={moduleStyles} {...rest} />;
};
