import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { TransitionProps } from './Transition.props';

type StatusType = 'idle' | 'enter' | 'enterActive' | 'exit' | 'exitActive';

interface TransitionState {
  classes: StatusType[];
  status: StatusType;
}

export const Transition = ({
  isActive,
  timeout,
  children,
  onExitDone,
}: TransitionProps): JSX.Element | null => {
  let timer = useRef<NodeJS.Timeout>();

  const [state, setState] = useState<TransitionState>({
    classes: [],
    status: 'idle',
  });

  useLayoutEffect(() => {
    if (isActive) {
      if (state.status === 'idle') {
        setState({
          classes: ['enter'],
          status: 'enter',
        });
      }
    } else {
      if (state.status === 'enterActive') {
        setState({
          classes: ['exit'],
          status: 'exit',
        });
      }
    }
  }, [isActive, state]);

  useEffect(() => {
    if (state.status === 'enter') {
      setState({
        classes: ['enter', 'enterActive'],
        status: 'enterActive',
      });
    } else if (state.status === 'exit') {
      setState({
        classes: ['exit', 'exitActive'],
        status: 'exitActive',
      });
      timer.current = setTimeout(() => {
        setState({
          classes: [],
          status: 'idle',
        });
        timer.current ?? clearTimeout(timer.current);
        onExitDone?.();
      }, timeout);
    }
    return () => {
      timer.current ?? clearTimeout(timer.current);
    };
  }, [timeout, state, onExitDone]);

  if (!isActive && state.status === 'idle') {
    return null;
  }

  return children(state.classes.join(' '));
};
