import { Radio } from 'components/forms/Radio/Radio';
import { ChangeEventHandler, useContext } from 'react';
import { QuizContext } from '..';
import { EstateType } from '../Quiz.types';
import styles from './QuizTwo.module.scss';

export const QuizTwo = (): JSX.Element => {
  const { state, dispatch } = useContext(QuizContext);
  const { estate } = state;

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value as EstateType;
    dispatch({ type: 'setEstate', estate: value });
    dispatch({ type: 'setNextButtonIsDisabled', isDisabled: !value });
  };

  return (
    <div className={styles.module}>
      <h3 className={styles.title}>Есть ли у вас имущество в собственности</h3>
      <div className={styles.list}>
        <label className={styles.item}>
          <Radio
            className={styles.radio}
            name="estate"
            value="Да"
            checked={estate === 'Да'}
            onChange={handleChange}
          />
          <span className={styles.name}>Да</span>
        </label>
        <label className={styles.item}>
          <Radio
            className={styles.radio}
            name="estate"
            value="Нет"
            checked={estate === 'Нет'}
            onChange={handleChange}
          />
          <span className={styles.name}>Нет</span>
        </label>
        <label className={styles.item}>
          <Radio
            className={styles.radio}
            name="estate"
            value=""
            checked={estate === ''}
            onChange={handleChange}
          />
          <span className={styles.name}>
            Ипотека <small>(в этом случае мы не можем Вам помочь)</small>
          </span>
        </label>
      </div>
    </div>
  );
};
