import { CloseButton } from 'components/elements';
import styles from './CookieBody.module.scss';
import { CookieBodyProps } from './CookieBody.props';

export const CookieBody = ({
  onClose,
  onPolicyClick,
  onConfirm,
}: CookieBodyProps): JSX.Element => {
  return (
    <div className={styles.module}>
      <CloseButton className={styles.close} onClick={onClose} />
      <p className={styles.text}>
        <span>
          Наш сайт использует файлы Cookie. Продолжая пользоваться сайтом, вы
          соглашаетесь на использование нами ваших файлов Cookie
        </span>{' '}
        <button className={styles.detail} onClick={onPolicyClick}>
          Подронее
        </button>
      </p>
      <button className={styles.confirm} onClick={onConfirm}>
        Хорошо, спасибо
      </button>
    </div>
  );
};
