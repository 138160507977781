import cn from 'classnames';
import { ThanksProps } from './Thanks.props';
import styles from './Thanks.module.scss';
import { CloseButton } from '..';

export const Thanks = ({
  onCloseClick,
  className,
}: ThanksProps): JSX.Element => {
  const moduleStyles = cn(styles.module, className);

  return (
    <div className={moduleStyles}>
      <CloseButton className={styles.close} onClick={onCloseClick} />
      <span className={styles.icon} />
      <h3 className={styles.title}>Спасибо, заявка отправлена!</h3>
      <p className={styles.subtitle}>
        В ближайшее время вам поступит звонок от адвоката с номера{' '}
        <b>+7 (961) 856-84-42</b>
      </p>
    </div>
  );
};
