import { Policy } from 'components/elements';
import { TextField } from 'components/forms';
import { useContext } from 'react';
import { QuizContext } from '..';
import styles from './QuizFive.module.scss';

export const QuizFive = (): JSX.Element => {
  const { state, dispatch } = useContext(QuizContext);

  return (
    <div className={styles.module}>
      <h3 className={styles.title}>Заполните форму</h3>
      <TextField
        className={styles.name}
        placeholder="Имя"
        value={state.name}
        onChange={(event) =>
          dispatch({ type: 'setName', name: event.target.value })
        }
      />
      <TextField
        className={styles.phone}
        placeholder="Телефон"
        type="tel"
        pattern="\d+"
        required
        value={state.phone}
        onChange={(event) =>
          dispatch({ type: 'setPhone', phone: event.target.value })
        }
      />
      <Policy
        kind="calculate"
        className={styles.policy}
        checked={state.policyIsChecked}
        onChange={() => dispatch({ type: 'togglePolicy' })}
        onClick={() => dispatch({ type: 'openPolicy' })}
      />
    </div>
  );
};
