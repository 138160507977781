import { Textual } from 'components/elements';
import { Modal } from 'components/layout';
import { Portal, Transition } from 'components/other';
import { useCookie } from 'hooks/useCookie';
import { useState } from 'react';
import { CookieBody } from './CookieBody/CookieBody';

export const Cookie = () => {
  const [isShow, setIsShow] = useState(true);
  const [cookie, updateCookie] = useCookie('cookie_notification');

  const [policyIsOpen, setPolicyIsOpen] = useState(false);

  const handleClose = () => setPolicyIsOpen(false);

  return !cookie && isShow ? (
    <>
      <CookieBody
        onClose={() => setIsShow(false)}
        onPolicyClick={() => setPolicyIsOpen(true)}
        onConfirm={() => updateCookie('confirmed', 365)}
      />
      <Transition isActive={policyIsOpen} timeout={300}>
        {(transitionStyles) => (
          <Portal withBlocking>
            <Modal className={transitionStyles} onOverlayClick={handleClose}>
              <Textual type="cookie" onCloseClick={handleClose} />
            </Modal>
          </Portal>
        )}
      </Transition>
    </>
  ) : null;
};
