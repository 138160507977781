import { FormEventHandler, useContext } from 'react';
import { CallbackContext } from '..';
import { CallbackModalBodyProps } from './CallbackModalBody.props';
import styles from './CallbackModalBody.module.scss';
import { TextField } from 'components/forms';
import { CloseButton, Policy } from 'components/elements';

declare const ym: any;
declare const fbq: any;

export const CallbackModalBody = ({
  type,
}: CallbackModalBodyProps): JSX.Element => {
  const { state, dispatch } = useContext(CallbackContext);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    ym(52597468, 'reachGoal', 'kick1');
    fbq('track', 'Lead');

    dispatch({ type: 'closeCallback' });
    dispatch({ type: 'openThanks' });

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: type,
        name: state.name,
        phone: state.phone,
      }),
    });
  };

  const types = {
    callback: 'Заказать звонок',
    debt: 'Списать долг',
    consultation: 'Бесплатная консультация',
    help: 'Помощь специалиста',
  };

  return (
    <div className={styles.module}>
      <CloseButton
        className={styles.close}
        onClick={() => dispatch({ type: 'closeCallback' })}
      />
      <form className={styles.form} onSubmit={handleSubmit}>
        <h2 className={styles.title}>{types[type]}</h2>
        <TextField
          className={styles.name}
          placeholder="Имя"
          value={state.name}
          onChange={(event) =>
            dispatch({ type: 'setName', name: event.target.value })
          }
        />
        <TextField
          className={styles.phone}
          placeholder="Телефон"
          type="tel"
          pattern="\d+"
          required
          value={state.phone}
          onChange={(event) =>
            dispatch({ type: 'setPhone', phone: event.target.value })
          }
        />
        <Policy
          className={styles.policy}
          checked={state.policyIsChecked}
          onChange={() => dispatch({ type: 'togglePolicy' })}
          onClick={() => dispatch({ type: 'openPolicy' })}
        />
        <button className={styles.button}>Отправить</button>
      </form>
    </div>
  );
};
