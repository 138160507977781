import { QuizBody } from './QuizBody/QuizBody';
import { QuizThanks } from './QuizThanks/QuizThanks';
import { QuizPolicy } from './QuizPolicy/QuizPolicy';

export const Quiz = (): JSX.Element => {
  return (
    <>
      <QuizBody />
      <QuizThanks />
      <QuizPolicy />
    </>
  );
};
